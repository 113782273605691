<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <div class="card mb-g">
      <div class="card-body pb-0 px-4">
        <div class="d-flex flex-row pb-3 pt-2  border-top-0 border-left-0 border-right-0">
          <h5 class="mb-0 flex-1 text-dark fw-500">
            Cập nhật thông tin tài khoản
          </h5>
          <span v-if="!confirming" class="text-muted fs-xs opacity-70"> Cập nhật lần cuối {{ timeUpdate }}</span>
        </div>
        <div class="pb-3 pt-2 border-top-0 border-left-0 border-right-0 text-muted">
          <form class="form" @submit.prevent="handleSubmit(summit)" autocomplete="off">
            <div class="form-row">
              <div class="col-md-4 mb-3" v-if="!confirming">
                <label class="form-label">Mã nhân viên</label>
                <input disabled type="text" class="form-control" id="code"
                       placeholder="Mã nhân viên"
                       autocomplete="false" :value="user.id + 100000">
              </div>
              <div class="col-md-4 mb-3">
                <ValidationProvider vid="name" name="Họ tên" rules="required"
                                    v-slot="{ errors, classes }">
                  <label class="form-label">Họ và tên <span class="text-danger">(*)</span></label>
                  <input type="text" class="form-control" id="name" placeholder="Họ và tên"
                         autocomplete="false" v-model="user.name" :class="classes">
                  <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-md-4 mb-3">
                <label class="form-label">Giới tính</label>
                <edutalk-select id="gender" v-model="user.profile.gender" class="w-100"
                                placeholder="Chọn giới tính">
                  <edutalk-option
                      v-for="item in [{id: 1, name: 'Nam'}, { id: 0, name: 'Nữ'}]"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name">
                  </edutalk-option>
                </edutalk-select>
              </div>
              <div class="col-md-4 mb-3">
                <label class="form-label">Vị trí</label>
                <input type="text" class="form-control" id="account_type" disabled
                       :value="user.profile.account_type && user.profile.account_type.name"
                       placeholder="Vị trí/Chức vụ">
              </div>
              <div class="col-md-4 mb-3">
                <label class="form-label">Trung tâm làm việc</label>
                <input type="text" class="form-control" disabled
                       :value="user?.center_names"
                       placeholder="Trung tâm làm việc">
              </div>
              <div class="col-md-4 mb-3">
                <label class="form-label">Ngày sinh <span class="text-danger">(*)</span></label>
                <ValidationProvider vid="birthday" name="Ngày sinh" rules="required"
                                    v-slot="{ errors, classes }">
                  <el-date-picker id="birthDay"
                                  format="dd-MM-yyyy"
                                  ref="birthday"
                                  name="birthday" :class="classes"
                                  value-format="dd-MM-yyyy" type="date" placeholder="Ngày sinh"
                                  v-mask="'##-##-####'" v-model="user.profile.birthday"
                                  :picker-options="pickerOptions"
                                  style="width: 100%;"
                  ></el-date-picker>
                  <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-md-4 mb-3">
                <label class="form-label" for="email">Email <span class="text-danger">(*)</span></label>
                <ValidationProvider vid="email" name="Email" rules="required"
                                    v-slot="{ errors, classes }">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="email">@</span>
                    </div>
                    <input type="email" ref="email" v-model="user.email" name="email"
                           :class="classes" class="form-control" id="email-input"
                           placeholder="Nhập email của bạn" aria-describedby="email">
                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-md-8 mb-3">
                <label class="form-label">Địa chỉ</label>
                <input v-model="user.profile.address" type="text" class="form-control" id="address"
                       placeholder="Nhập địa chỉ thường trú">
              </div>

              <div class="col-md-3 mb-3">
                <label class="form-label">Số điện thoại <span class="text-danger">(*)</span></label>
                <ValidationProvider vid="phone" name="Số điện thoại"
                                    :rules="{required: true, min: 10, regex: /((09|03|07|08|05)+([0-9]{8})\b)/g }"
                                    v-slot="{ errors, classes }">
                  <div class="input-group">
                    <the-mask v-model="user.phone" ref="phone" :class="classes"
                              placeholder="Nhập số điện thoại"
                              class="form-control" id="phone-input" :mask="['##########']"/>
                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <button :disabled="loading" class="btn btn-primary waves-effect waves-themed" type="submit"><i
                v-if="loading" class="el-icon-loading"></i> Cập nhật thông tin
            </button>
          </form>
        </div>
      </div>
    </div>
    <!--eslint-disable-->
  </ValidationObserver>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import {USER_PROFILE, USER_UPDATE} from "@/core/services/store/profile.module";
export default {
  name: "EdutalkInformation",
  components: {},
  props: {
    confirming: Boolean,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      loading: false,
      user: {
        profile: {
          gender: '',
          birthday: '',
          xac_thuc_sdt: '',
          account_type: {
            name: ''
          },
          center: {
            name: ''
          }
        },
        user_positions: {},
        phone: '',
        verified_phone: '',
      },
      plurality: null,
      verifyStatus: true,
      dialogVerify: false,
      passValidate: false,
      showInputCode: false,
      verifyButton: false,
      counting: false,
      appVerifier: '',
      timeUpdate: '',
      codeOTP: '',
      options: [
        {name: 'Nam', id: 1},
        {name: 'Nữ', id: 0},
      ],
      concurrentOption: [
        {id: 'TPNS', name: 'TP Nhân sự'},
        {id: 'TPOnline', name: 'TP Online'},
        {id: 'TVV', name: 'Tư vấn viên'},
        {id: 'SLX', name: 'SLX'},
        {id: 'TPTT', name: 'TP Truyền thông'},
      ],
    }
  },
  created() {
  },
  mounted() {
    this.getProfileUser();
    if (this.errors) {
      this.$refs.form.setErrors(this.errors);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(['currentUser'])
  },
  methods: {
    summit() {
      this.passValidate = false;
      this.loading = true;
      let formData = new FormData();
      let gender = this.user.profile.gender ? this.user.profile.gender : '';
      formData.append('name', this.user.name);
      formData.append('email', this.user.email);
      formData.append('gender', gender);
      formData.append('phone', this.user.phone);
      formData.append('account_type', this.user.profile.account_type?.name);
      formData.append('address', this.user.profile.address);
      formData.append('birthday', this.user.profile.birthday.split("-").reverse().join("-"));
      formData.append('profile_id', this.user.profile.id);
      this.$store.dispatch(USER_UPDATE, formData)
          .then(() => {
            this.$message({
              type: 'success',
              message: 'Cập nhật thông tin tài khoản thành công',
              showClose: true
            });
            this.$refs.form.reset();
            this.loading = false;
            this.changeTimeUpdate();
          });
      if (this.errors)
        this.$refs.form.setErrors(this.errors);

    },
    changeTimeUpdate() {
      this.timeUpdate = this.$moment(this.user.updated_at).fromNow();
    },
    getProfileUser() {
      this.$store.dispatch(USER_PROFILE).then((res) => {
        this.user = res.data;
        // this.user.profile.center.name = res.data.profile?.center?.name;
        this.user.profile.birthday = (this.user.profile.birthday && this.user.profile.birthday != "0000-00-00") ? this.user.profile.birthday.split("-").reverse().join("-") : '';
        this.plurality = this.user.user_positions.map((position) => {
          return position.position;
        });
        this.user.center_names = this.user.center_teacher.map(center => center.center.name).join(',');
      });
      this.changeTimeUpdate();
    },

    onChange() {
      this.verifyButton = false;
    },
    onComplete(v) {
      this.verifyButton = true;
      this.codeOTP = v;
    },
    startCountdown: function () {
      this.counting = true;
    },
    onCountdownEnd: function () {
      this.counting = false;
    },
    getErrors(scope) {
      try {
        const errors = this.$refs[scope].errors
        let parsed = []
        Object.keys(errors).map((key) => {
          parsed.push(errors[key][0])
        });
        return parsed
      } catch {
        return false
      }
    },
  },
};
</script>