<template>
  <ValidationObserver ref="banks" v-slot="{ handleSubmit }">
    <div class="card mb-g show">
      <div class="row row-grid no-gutters dropdown-menu-animated">
        <div class="col-12">
          <div class="p-3">
            <h2 class="mb-0 fs-xl header_bank">
              Tài khoản ngân hàng
              <p> {{ userBank.updated_at ? 'Cập nhật lần cuối lúc: ' : '' }}
                {{ formatDateTimeVietnam(userBank.updated_at) }} </p>
            </h2>
          </div>
        </div>
        <div class="col-12">
          <div class="p-3">
            <form @submit.prevent="handleSubmit(summit)" autocomplete="off">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label class="form-label">Chủ tài khoản</label>
                  <ValidationProvider vid="owner_name" name="Họ tên ngân hàng"
                                      :rules="{required: true, regex: /^([a-zA-Z]+\s)*[a-zA-Z]+$/ }"
                                      v-slot="{errors,  classes }">
                    <div class="input-group">
                      <input type="text" class="form-control" :class="classes" @input="upperCase"
                             v-model="userBank.owner_name" placeholder="Đầy đủ họ tên, không dấu" autocomplete="false">
                      <div class="input-group-append">
                                        <span class="input-group-text">
                                            <i class="far fa-exclamation-triangle color-danger-900"></i> Tài khoản ngân hàng nhập phải là chính chủ </span>
                      </div>
                      <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                        {{
                          !userBank.owner_name ? 'Họ tên ngân hàng là bắt buộc' : errors[0] ? 'Bạn đã nhập sai định dạng, vui lòng nhập lại.' : ''
                        }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label class="form-label">Chọn ngân hàng</label>
                  <ValidationProvider vid="ngan_hang" name="Ngân hàng" rules="required" v-slot="{ errors, classes }">
                    <edutalk-select v-model="userBank.ngan_hang" @change="changeBank" class="w-100" :class="classes"
                                    clearable filterable placeholder="Chọn ngân hàng">
                      <edutalk-option
                          v-for="item in banks"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                      >
                      </edutalk-option>
                    </edutalk-select>
                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                      {{ errors[0] }}
                    </div>
                  </ValidationProvider>
                </div>
                <div v-if="hideProvince" class="col-md-6 mb-3">
                  <label class="form-label">Tỉnh thành</label>
                  <ValidationProvider vid="province" name="Tỉnh thành" :rules="hideProvince ? 'required' : ''"
                                      v-slot="{ errors , classes }">
                    <edutalk-select v-model="userBank.province" :class="classes" @change="changeProvince" class="w-100"
                                    clearable filterable placeholder="Chọn tỉnh thành">
                      <edutalk-option
                          v-for="item in provinces"
                          :key="item"
                          :label="item"
                          :value="item">
                      </edutalk-option>
                    </edutalk-select>
                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                      {{ errors[0] }}
                    </div>
                  </ValidationProvider>
                </div>
                <div v-if="hideProvince" class="col-md-6 mb-3">
                  <label class="form-label">Chi nhánh</label>
                  <ValidationProvider vid="chi_nhanh" name="Chi nhánh" :rules="hideProvince ? 'required' : ''"
                                      v-slot="{ errors, classes }">
                    <edutalk-select v-model="userBank.chi_nhanh" class="w-100" clearable filterable :class="classes"
                                    placeholder="Chọn chi nhánh">
                      <edutalk-option
                          v-for="item in agency"
                          :key="item"
                          :label="item"
                          :value="item">
                      </edutalk-option>
                    </edutalk-select>
                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                      {{ errors[0] }}
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                  <label class="form-label">Số tài khoản</label>
                  <ValidationProvider vid="stk" name="Số tài khoản" :rules="{required: true, regex: /^[0-9]+$/ }"
                                      v-slot="{ errors, classes }">
                    <input type="text" class="form-control" placeholder="Số tài khoản ngân hàng" :class="classes"
                           v-model="userBank.stk" autocomplete="false">
                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                      {{
                        !userBank.stk ? 'Số tài khoản là bắt buộc' : errors[0] ? 'Bạn đã nhập sai định dạng, vui lòng nhập lại.' : ''
                      }}
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <button :disabled="loading" class="btn btn-primary waves-effect waves-themed"><i v-if="loading"
                                                                                               class="el-icon-loading"></i>
                Cập nhật thông tin ngân hàng
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import provinces from '@/core/config/provinces';
import agency from '@/core/config/agency';
import banks from '@/core/config/banks';
import {USER_UPDATE_BANK} from "@/core/services/store/profile.module";
import moment from "moment-timezone";

export default {
  name: "EdutalkBank",
  data() {
    return {
      userBank: {
        chi_nhanh: "",
        ngan_hang: "",
        stk: "",
        province: "",
        owner_name: "",
        updated_at: "",
      },
      provinces: provinces,
      agency: [],
      banks: banks,
      hideProvince: false,
      loading: false
    }
  },
  watch: {
    currentUser: {
      handler() {
        this.userBank = this.currentUser.bank_accounts.length > 0 ? this.currentUser.bank_accounts[0] : this.userBank;

      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.userBank = this.currentUser.bank_accounts.length > 0 ? this.currentUser.bank_accounts[0] : this.userBank;
    if (this.userBank.ngan_hang === 'Agribank') {
      this.hideProvince = true;
    }

  },
  methods: {
    formatDateTimeVietnam(value) {
      if (value) {
        if (value.includes('00Z')) {
          return moment(String(value)).format('HH:mm:ss DD-MM-YYYY')
        } else {
          return value;
        }
      }
    },
    changeBank() {
      this.hideProvince = false;
      if (this.userBank.ngan_hang === 'Agribank') {
        this.hideProvince = true;
        this.userBank.province = '';
        this.userBank.chi_nhanh = '';
      }
    },
    changeProvince() {
      this.agency = agency[this.userBank.province];
      this.userBank.chi_nhanh = '';
    },
    summit() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth();
      let firstDay = date && new Date(year, month , 5, 18);
      let lastDay = date && new Date(year, month, 12, 9);
      let lastMonth = this.$moment().subtract(1, 'months').format('MM/YYYY')
      if (date && date >= firstDay && date <= lastDay) {
        //`Đã hết thời hạn cập nhật thông tin tài khoản ngân hàng để nhận thu nhập tháng ${date.getMonth()}/${date.getFullYear()}. Thông tin này sẽ được sử dụng để trả thu nhập tháng kế tiếp.`
        const h = this.$createElement;
        this.$msgbox({
          title: 'Thông báo',
          message: h('p', null, [
            h('span', null, 'Đã hết thời hạn cập nhật thông tin tài khoản ngân hàng để nhận thu nhập tháng '),
            h('i', {style: 'color: teal'}, `${lastMonth}`),
            h('br', null, null),
            h('span', null, `Thông tin này sẽ được sử dụng để trả thu nhập tháng kế tiếp.`)
          ]),
          showCancelButton: true,
          showClose: false,
          confirmButtonText: 'Tiếp tục',
          cancelButtonText: 'Hủy',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              this.storeBank();
            }
            done();
          }
        })
      } else {
        this.storeBank()
      }
    },
    upperCase(e) {
      this.userBank.owner_name = e.target?.value?.toLocaleUpperCase();
    },
    storeBank() {
      let params = {
        agency_bank: this.userBank.chi_nhanh,
        bank_name: this.userBank.ngan_hang,
        bank_number: this.userBank.stk,
        province_bank: this.userBank.province,
        owner_card_bank: this.userBank.owner_name,
      };
      this.loading = true;
      this.$store.dispatch(USER_UPDATE_BANK, params)
          .then((res) => {
            this.userBank.updated_at = res.data.last_update_bank;
            this.$message({
              type: 'success',
              message: 'Cập nhật thông tin ngân hàng thành công!',
              showClose: true
            });
            this.loading = false;
            this.$refs.banks.reset();
            // this.setData(res.data);
          });
      if (this.errors) {
        this.$refs.banks.setErrors(this.errors);
      }
    },
    setData(data) {
      this.userBank.chi_nhanh = data.agency_bank ? data.agency_bank : '';
      this.userBank.ngan_hang = data.bank_name;
      this.userBank.stk = data.bank_number;
      this.userBank.province = data.province_bank;
      this.userBank.owner_name = data.owner_card_bank;
      this.userBank.updated_at = data.last_update_bank;
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(['currentUser'])
  }
};
</script>
