<template>
    <ValidationObserver ref="password" v-slot="{ handleSubmit }">
        <div class="card mb-g">
        <div class="card-body pb-0 px-4">
            <div class="d-flex flex-row pb-3 pt-2  border-top-0 border-left-0 border-right-0">
                <h5 class="mb-0 flex-1 text-dark fw-500">
                    Thay đổi mật khẩu
                </h5>
                <span class="text-muted fs-xs opacity-70">Đề phòng lộ thông tin tài khoản hãy đặt mật khẩu có tính bảo mật cao</span>
            </div>
            <div class="pb-3 pt-2 border-top-0 border-left-0 border-right-0 text-muted">
                <form @submit.prevent="handleSubmit(summit)" autocomplete="off">
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label class="form-label" >Mật khẩu cũ</label>
                        <ValidationProvider vid="old_password" name="Mật khẩu cũ" rules="required|min:6"
                                            v-slot="{ errors, classes }">
                          <el-input v-model="old_password" :class="classes" placeholder="Nhập mật khẩu cũ"  autocomplete="false" show-password></el-input>
                          <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                            {{ errors[0] }}
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6 mb-3">
                            <label class="form-label">Mật khẩu mới</label>
                            <ValidationProvider vid="new_password" name="Mật khẩu mới" rules="required|min:6"
                                                v-slot="{ errors, classes }">
                                <input type="password" v-model="new_password"  class="form-control" :class="classes" placeholder="Nhập mật khẩu mới" autocomplete="false">
                                <div class="invalid-feedback"  data-field="name" data-validator="notEmpty">
                                    {{ errors[0] }}
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="form-label" >Nhập lại mật khẩu mới</label>
                            <ValidationProvider vid="confirm_password" name="Nhập lại mật khẩu mới" rules="required|min:6|confirmed:new_password"
                                                v-slot="{ errors, classes }">
                                <input type="password" v-model="confirm_password" class="form-control" :class="classes" placeholder="Nhập lại mật khẩu mới" autocomplete="false">
                                <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                                    {{ errors[0] }}
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <button class="btn btn-primary waves-effect waves-themed" type="submit">Cập nhật mật khẩu
                    </button>
                </form>
            </div>
        </div>
    </div>
    </ValidationObserver>
</template>

<script>
    import {USER_CHANGE_PASSWORD} from "@/core/services/store/profile.module";

    export default {
        name: "EdutalkPassword",
        data() {
            return {
                old_password: '',
                new_password: '',
                confirm_password: ''
            }
        },
        methods: {
            summit() {
                let old_password = this.old_password;
                let new_password = this.new_password;
                let confirm_password = this.confirm_password;
                this.$store.dispatch(USER_CHANGE_PASSWORD, { old_password, new_password, confirm_password } )
                    .then((response) => {
                        if (response.status === 422) {
                            this.$refs.password.setErrors(response.data.data);
                        } else {
                            this.$message({ type: 'success', message: `${response.message}`, showClose: true });
                            this.old_password = this.new_password = this.confirm_password = '';
                            this.$refs.password.reset();
                        }
                    })
                    .catch((error) => {
                        this.$notify({title: 'Warning', message: error, type: 'warning' });
                    })
            }
        }
    };
</script>
