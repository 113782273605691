<template>
    <div class="row">
        <div class="col-lg-6 col-xl-2 order-lg-1 order-xl-1">
            <!-- profile summary -->
            <div class="card mb-g rounded-top">
                <div class="row no-gutters row-grid">
                    <div class="col-12">
                        <div class="d-flex flex-column align-items-center justify-content-center p-4">
                            <div class="position-relative">
                                <el-image :src="profile.image" class="rounded-circle image-avatar shadow-2 img-thumbnail">
                                    <!--eslint-disable-->
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </div>
                            <h5 class="mb-0 fw-700 text-center mt-3">
                                {{ currentUser.name }}
                                <small class="text-muted mb-0">{{ currentUser.email }}</small>
                            </h5>
                        </div>
                    </div>
                    <!--Filter accordions-->
                    <div id="panel-2" class="w-100">
                        <div class="panel-container">
                            <div class="panel-content">
                                <div class="border bg-light rounded-top">
                                    <div id="js_list_accordion" class="accordion accordion-hover accordion-clean">
                                        <div @click="changeActive('information')" class="card ">
                                            <div class="card-header">
                                                <a href="javascript:void(0);" class="card-title"
                                                   v-bind:class="{ collapsed : !active.information}">
                                                    <i class="fal fa-cog width-2 fs-xl"></i>
                                                    Thông tin chi tiết
                                                </a>
                                            </div>
                                        </div>
                                        <div @click="changeActive('changePassword')" class="card">
                                            <div class="card-header">
                                                <a href="javascript:void(0);" class="card-title"
                                                   v-bind:class="{ collapsed : !active.changePassword}">
                                                    <i class="fal fa-cloud-upload-alt width-2 fs-xl"></i>
                                                    Thay đổi mật khẩu
                                                </a>
                                            </div>
                                        </div>

<!--                                      <div @click="changeActive('changePassword')" class="card">-->
<!--                                        <div class="card-header">-->
<!--                                          <a href="javascript:void(0);" class="card-title"-->
<!--                                             v-bind:class="{ collapsed : !active.changePassword}">-->
<!--                                            <i class="fal fa-cloud-upload-alt width-2 fs-xl"></i>-->
<!--                                            Thay đổi mật khẩu-->
<!--                                          </a>-->
<!--                                        </div>-->
<!--                                      </div>-->

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12  col-xl-6 order-lg-3 order-xl-2">
            <edutalk-information v-if="active.information"></edutalk-information>
            <edutalk-password v-if="active.changePassword"></edutalk-password>
        </div>
        <div class="col-lg-6  col-xl-4 order-lg-2 order-xl-3">
          <edutalk-brank v-if="active.information"></edutalk-brank>
        </div>

        <!--eslint-disable-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import EdutalkPassword from "@/views/pages/user/edutalk-password";
    import EdutalkInformation from "@/views/pages/user/edutalk-information";
    import EdutalkBrank from "@/views/pages/user/edutalk-brank";
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    const _ = deepdash(lodash);

    import {mapGetters} from "vuex";
    import {USER_UPLOAD_IMAGE} from "@/core/services/store/profile.module";

    export default {
        name: "EdutalkProfile",
        components: {
            EdutalkPassword,
            EdutalkInformation,
            EdutalkBrank
        },
        data() {
            return {
                profile: {
                    image: ''
                },
                active: {
                    information: true,
                    identity: false,
                    changePassword: false,
                },
                dialogFormVisible: false,
                image: {
                    src: null,
                    type: null,
                },
                event: '',
                result: {
                    coordinates: null,
                    image: null
                },
                selected: [],
                options: [
                    {name: 'Nam', id: 1},
                    {name: 'Nữ', id: 0},
                ],
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Thông tin tài khoản", icon: 'far fa-home'}
            ]);
            this.profile.image = this.currentUser.image
        },
        methods: {
            changeActive(tab) {
                _.mapKeysDeep(this.active, (value, key) => {
                    return this.active[key] = key === tab;
                });
            },
            onChange({ coordinates, image }) {
                this.result = { coordinates,  image };
            },
            cropImage() {
                let vm = this;
                const { canvas } = this.$refs.cropper.getResult();
                this.profile.image = canvas.toDataURL();
                if (canvas) {
                    const form = new FormData();
                    canvas.toBlob(blob => {
                        form.append('file', blob);
                        form.append('id', vm.currentUser.id);
                        this.$store.dispatch(USER_UPLOAD_IMAGE, form)
                            .then((response) => {
                                vm.$message.success(response.message);
                            }).catch((error) => {
                                vm.$message.warning(error.message);
                            });
                        this.dialogFormVisible = false;
                    }, 'image/jpeg',0.8);
                }
            },
            reset() {
                this.image = {
                    src: null,
                    type: null,
                };
            },
            closed () {
                this.$refs.input.value = '';
            },
            loadImage(event) {
                const {files} = event.target;
                // Ensure that you have a file before attempting to read it
                if (files && files[0]) {
                    // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
                    if (this.image.src) {
                        URL.revokeObjectURL(this.image.src)
                    }
                    // 2. Create the blob link to the file to optimize performance:
                    const blob = URL.createObjectURL(files[0]);

                    // Create a new FileReader to read this image binary data
                    const reader = new FileReader();
                    // Define a callback function to run, when FileReader finishes its job
                    reader.onload = (e) => {
                        // Note: arrow function used here, so that "this.image" refers to the image of Vue component
                        this.image = {
                            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
                            src: blob,
                            // Determine the image type to preserve it during the extracting the image from canvas:
                            type: getMimeType(e.target.result, files[0].type),
                        };
                    };
                    // Start the reader job - read file as a data url (base64 format)
                    reader.readAsArrayBuffer(files[0]);
                }
                this.dialogFormVisible = true;
            }
        },
        computed: {
            ...mapGetters(['currentUser'])
        }
    };

    // This function is used to detect the actual image type,
    function getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }
</script>
